import type { TrustPilotRating } from '@nexdynamic/squeegee-common';
import { FeedbackPromptResult, ProductLevel } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { FeedbackService } from '../Components/Feedback/FeedbackService';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { RemoteSupport } from '../RemoteSupport';
import { Api } from '../Server/Api';
import { ReleaseNotesDialog } from './ReleaseNotes';

const data = require('../../package.json');

export class About {
    protected rating: { description: string; score: number; stars: string };
    protected enableRemoteSupport = () => RemoteSupport.enableRemoteSupport();
    protected disableRemoteSupport = () => RemoteSupport.disableRemoteSupport();
    supportAuthCode: number | string;
    authCodeTimer: NodeJS.Timeout;

    protected remoteSupportInitialised = RemoteSupport.remoteSupportInitialised;

    protected heading = 'About';

    protected version = About._getVersionDescription();

    private static _getVersionDescription() {
        return `v${data.version}`;
    }
    protected deviceKey = ApplicationState.deviceId;
    protected launchReview = (<any>window).LaunchReview;
    protected copyrightYear = new Date().getFullYear().toString();
    protected isMobile = GlobalFlags.isMobile;
    protected devMode = ApplicationState.stateFlags.devMode;
    protected notSubscribed: boolean;
    protected connectionData = Api.connectionData;
    protected canUpgrade: boolean;

    protected networkStatus = navigator.onLine
        ? ApplicationState.localise('network.available')
        : ApplicationState.localise('network.offline-warning');

    protected clientVersionStatus = ApplicationState.clientVersionStatus || 'not checked';

    public async attached() {
        this.initSupportAuthCode();
        const subscription = await Api.getSubscription();
        if (subscription && subscription.product && subscription.product.productLevel === ProductLevel.NotSubscribed) {
            this.notSubscribed = true;
        }
        this.canUpgrade = ApplicationState.isAppleApproved && this.notSubscribed;
        this.initRating();
    }

    public async detached() {
        clearInterval(this.authCodeTimer);
    }

    protected restart() {
        try {
            document.location && document.location.reload();
        } catch (error) {
            return;
        }
    }

    protected async showReleaseNotes() {
        const dialog = new ReleaseNotesDialog();
        dialog.show(DialogAnimation.SLIDE);
    }

    protected async rate() {
        FeedbackService.rate();
    }

    protected feedbackUrl = FeedbackService.feedbackUrl;
    protected getSupport = () => ApplicationState.getSupport('About Account Setup', false);
    protected async feedback() {
        await FeedbackService.sendFeedback();
        const promptResult = new FeedbackPromptResult(new Date().getTime().toString(), 'gave-feedback', `${data.version}`);
        await FeedbackService.saveFeedbackOrRatingResult(promptResult);
        new LoaderEvent(false);
    }

    protected goto(fragment: string) {
        ApplicationState.router && ApplicationState.router.navigate(fragment);
    }

    private async initRating() {
        const rating = await Api.get<TrustPilotRating>(null, '/admin/rating');
        if (!rating?.data?.starsString || !rating.data.businessEntity?.trustScore) return;

        const description = rating.data.starsString;
        const score = Number((rating.data.businessEntity.trustScore || 4.6).toFixed(1));
        const stars = `https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${Math.round(score * 2) / 2}.svg`;

        this.rating = { description, score, stars };
    }

    private initSupportAuthCode() {
        // Generates a random 4 digit number for support authentication
        this.generateSupportAuthCode();
        this.authCodeTimer = setInterval(() => {
            this.generateSupportAuthCode();
        }, 60000);
    }

    private async generateSupportAuthCode() {
        const authCode = await Api.registerSupportAuthCode().catch(error => {
            Logger.error(error);
            clearInterval(this.authCodeTimer);
        });
        this.supportAuthCode = authCode || ApplicationState.localise('about.support-auth-code-error');
    }
}
